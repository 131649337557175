import React, { useEffect, useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import Alert from "app/components/Alert";
import { useCheckout } from "modules/selectors";
import useCheckoutEstimate from "hooks/Subscriptions/useCheckoutEstimate";
import PoweredByStripeIcon from "app/components/PoweredByStripeIcon";
import { OnApproveData } from "@paypal/paypal-js";
import { DateStrFormat, getDateString } from "helpers/getDateString";
import {
  useStripeCard,
  useCheckoutWithStripe,
  useCheckoutWithPaypal,
} from "hooks/Checkout";
import { PaypalButton } from "app/components/Checkout/PaypalButton";
import { SubmitButton } from "app/components/Checkout/SubmitButton";
import LoaderOverlay from "app/components/Loader/LoaderOverlay";
import { useUserHasTrialed } from "hooks/Subscriptions/useUserHasTrialed";
import { PaymentRequestButtons } from "app/components/Checkout/PaymentRequestButtons";
import { useCheckoutWithWallet } from "hooks/Checkout/useCheckoutWithWallet";
import {
  useApplePayFlag,
  useGooglePayFlag,
  usePaypalCheckoutFlag,
} from "hooks/useFeatureFlags";
import { PaymentRequest } from "@stripe/stripe-js";
import styles from "./styles.module.scss";

export function Payment() {
  const [errorPayment, setErrorPayment] = useState(false);

  const isPaypalCheckoutAvailable = usePaypalCheckoutFlag();
  const isGooglePayAvailable = useGooglePayFlag();
  const isApplePayAvailable = useApplePayFlag();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null
  );

  const isExpressCheckoutAvailable =
    isGooglePayAvailable || isApplePayAvailable || isPaypalCheckoutAvailable;
  const shouldRenderExpressCheckoutCopy =
    paymentRequest || isPaypalCheckoutAvailable;

  const { errorCard, onCardNumberChange } = useStripeCard();

  const { checkoutEstimate } = useCheckoutEstimate();
  const { couponEstimate } = checkoutEstimate || {};
  const discountDuration = couponEstimate?.coupon?.discountDuration || 0;

  const { selectedPlan } = useCheckout();
  const { canTrial: canPlanTrial } = selectedPlan;
  const { hasTrialed } = useUserHasTrialed();
  const canTrial = canPlanTrial && !hasTrialed;
  const { stripe } = useCheckoutWithStripe();
  const { paypal } = useCheckoutWithPaypal();
  const { wallet } = useCheckoutWithWallet();

  const isSubscriptionCreateLoading =
    stripe.loading || paypal.loading || wallet.loading;

  useEffect(() => {
    const err = stripe.error || paypal.error || wallet.error;

    if (!err) {
      return;
    }

    setErrorPayment(true);
  }, [stripe.error, paypal.error, wallet.error]);

  const disclaimer = (
    <>
      {canTrial
        ? `By clicking on "Try free & subscribe",
            you will be enrolled in recurring payments for the amount
            selected after your trial period ends on
            ${getDateString(
              DateStrFormat.MMDDYYYY,
              selectedPlan.trialEndTime
            )}.`
        : `By clicking on "Subscribe now", you will be enrolled in
            recurring payments for the amount selected on 
            ${getDateString(DateStrFormat.MMDDYYYY)}.`}
      {couponEstimate?.coupon &&
        ` The first ${discountDuration > 1 ? discountDuration : ""} 
          ${selectedPlan.periodUnit} ${discountDuration > 1 ? "s" : ""} 
          of your subscription will cost $${couponEstimate?.discountPlanPriceInCents /
            100}
          that will renew at $${selectedPlan.priceInCents / 100}/${
          selectedPlan.periodUnit
        }.`}
      {" You can cancel anytime. For more information, "}
      <a
        style={{ textDecoration: "underline" }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://steezy.zendesk.com/hc/en-us/sections/360011158532-Canceling-Your-Subscription"
      >
        click here
      </a>
      {"."}
    </>
  );

  return (
    <>
      <div className={styles.paymentContainer}>
        {errorPayment && (
          <div className={styles.errorAlertWrapper}>
            <Alert variant="danger" closeAlert={() => setErrorPayment(null)}>
              <p>
                {" "}
                Sorry, something went wrong. Please try again or{" "}
                <a
                  href="https://steezy.zendesk.com/hc/en-us/requests/new"
                  className={styles.zendeskLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  contact us for help!
                </a>
              </p>
            </Alert>
          </div>
        )}

        <div>
          {isExpressCheckoutAvailable && (
            <div>
              {shouldRenderExpressCheckoutCopy && (
                <h3 className={styles.expressCheckoutHeader}>
                  Express Checkout
                </h3>
              )}
              <div>
                {isPaypalCheckoutAvailable && (
                  <PaypalButton
                    loading={paypal.loading}
                    error={paypal.error}
                    createBillingAgreement={paypal.createBillingAgreement}
                    onApprove={async (data: OnApproveData) => {
                      await paypal.checkoutPaypal(data);
                    }}
                    onError={() => setErrorPayment(true)}
                  />
                )}
                <PaymentRequestButtons
                  selectedPlan={selectedPlan}
                  onSubmit={({ paymentIntent, walletName }) =>
                    wallet.checkoutWallet({ paymentIntent, walletName })
                  }
                  onError={() => setErrorPayment(true)}
                  paymentRequest={paymentRequest}
                  setPaymentRequest={setPaymentRequest}
                />
              </div>
            </div>
          )}
          {shouldRenderExpressCheckoutCopy && (
            <div className={styles.or}>
              <div />
              <h6 style={{ fontWeight: 500 }}>or</h6>
              <div />
            </div>
          )}

          <div className={styles.cardElementTitle}>
            <h3 className={styles.checkoutOptionHeader}>Pay with card</h3>
            <PoweredByStripeIcon />
          </div>
          <div className={styles.cardElementWrapper}>
            <CardElement
              onChange={onCardNumberChange}
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    iconColor: "#1b1e22",
                    color: "#333",
                    lineHeight: "40px",
                    fontWeight: "500",
                    fontFamily: "Helvetica Neue",
                    fontSize: "15px",
                    "::placeholder": { color: "#BCBABA" },
                  },
                  complete: { color: "#00A779" },
                  invalid: { color: "#c10000" },
                },
              }}
            />
            {errorCard && (
              <small className={styles.cardError}>{errorCard}</small>
            )}
          </div>
          <div>
            <SubmitButton
              disclaimer={disclaimer}
              disabled={isSubscriptionCreateLoading || !!errorCard}
              onClick={() => stripe.checkoutStripe()}
            >
              {canTrial ? "TRY FREE & SUBSCRIBE" : "SUBSCRIBE NOW"}
            </SubmitButton>
          </div>
        </div>
      </div>
      {isSubscriptionCreateLoading && <LoaderOverlay />}
    </>
  );
}
