import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import env from "helpers/env";
import { Elements as ElementsProvider } from "@stripe/react-stripe-js";
import { PaymentMethodType, PaymentSource } from "services/graphql";
import SpanLink from "app/components/SpanLink";
import zendesk from "helpers/zendesk";
import Button from "app/components/Button";
import useSubscriptionCreateForCustomer from "hooks/Checkout/useSubscriptionCreateForCustomer";
import Alert from "app/components/Alert";
import { UpdatePaymentMethod } from "app/components/Checkout/UpdatePaymentMethod";
import { usePrimaryPaymentSource } from "hooks/Subscriptions/usePrimaryPaymentSource";
import { convertPaymentSourceToString } from "helpers/convertPaymentSourceToString";
import { useIsLargeScreen } from "hooks/index";
import styles from "./styles.module.scss";
import { Payment } from "../Payment";
import { AppReviews } from "../AppReviews";
import { CheckoutHeader } from "../CheckoutHeader";
import { LogoNav } from "../LogoNav";
import sharedStyles from "../shared-styles.module.scss";

const stripePromise = loadStripe(env("PUBLIC_STRIPE_PUBLISHABLE_KEY"));

interface Props {
  headerText: string;
  setIsSelectingPlan(isSelectingPlan: boolean): void;
}

export function CheckoutFlow({ headerText, setIsSelectingPlan }: Props) {
  const {
    primaryPaymentSource,
    loadingPrimaryPaymentSource,
  } = usePrimaryPaymentSource();
  const isLargeScreen = useIsLargeScreen();
  return (
    <div
      style={{
        background: "#eff1f4",
        position: "absolute", // Need to fit entire screen
        width: "100%",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <div className={sharedStyles.mainContainer}>
        <LogoNav />

        <div className={sharedStyles.checkoutContainer}>
          {isLargeScreen ? (
            <div className={sharedStyles.checkoutContainerInner}>
              <CheckoutHeader
                headerText={headerText}
                setIsSelectingPlan={setIsSelectingPlan}
              />
              <div className={sharedStyles.sectionContainer}>
                <p style={{ fontSize: "12px" }}>
                  Need help with your account? Please email us at{" "}
                  <a
                    href="mailto:support@steezy.co"
                    className={sharedStyles.link}
                  >
                    support@steezy.co
                  </a>
                  !
                </p>
              </div>
            </div>
          ) : (
            <div className={sharedStyles.checkoutContainerInner}>
              <CheckoutHeader
                headerText={headerText}
                setIsSelectingPlan={setIsSelectingPlan}
              />
            </div>
          )}
          {isLargeScreen ? (
            <div className={sharedStyles.checkoutContainerInner}>
              <AppReviews />
              <div className={sharedStyles.sectionContainer}>
                <h2 className={sharedStyles.sectionHeader}>Checkout</h2>
                {!loadingPrimaryPaymentSource && (
                  <PaymentMethod primaryPaymentSource={primaryPaymentSource} />
                )}
              </div>
            </div>
          ) : (
            <div className={sharedStyles.checkoutContainerInner}>
              <div className={sharedStyles.sectionContainer}>
                {!loadingPrimaryPaymentSource && (
                  <PaymentMethod primaryPaymentSource={primaryPaymentSource} />
                )}
              </div>
              <AppReviews />
              <div className={sharedStyles.sectionContainer}>
                <p style={{ fontSize: "12px" }}>
                  Need help with your account? Please email us at{" "}
                  <a
                    href="mailto:support@steezy.co"
                    className={sharedStyles.link}
                  >
                    support@steezy.co
                  </a>
                  !
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function PaymentMethod({
  primaryPaymentSource,
}: {
  primaryPaymentSource: Partial<PaymentSource>;
}) {
  const {
    subscriptionCreateForCustomer,
    subscriptionCreateForCustomerResults,
  } = useSubscriptionCreateForCustomer();
  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = useState(false);

  if (!primaryPaymentSource) {
    return (
      <ElementsProvider stripe={stripePromise}>
        <Payment />
      </ElementsProvider>
    );
  }

  if (isUpdatingPaymentMethod) {
    return (
      <div className={styles.paymentContainer}>
        <UpdatePaymentMethod
          onSuccess={() => setIsUpdatingPaymentMethod(false)}
          onCancel={() => setIsUpdatingPaymentMethod(false)}
        />
      </div>
    );
  }

  const { type } = primaryPaymentSource;
  const { paymentMethodCopy, subscribeCopy } = convertPaymentSourceToString(
    primaryPaymentSource
  );

  switch (type) {
    case PaymentMethodType.ApplePay:
    case PaymentMethodType.GooglePay:
    case PaymentMethodType.Card:
    case PaymentMethodType.PaypalExpressCheckout:
      return (
        <div className={styles.paymentContainer}>
          <div className={styles.paymentMethodContainer}>
            <header>Current Payment Method</header>
            <p>
              {paymentMethodCopy}
              <div>
                <SpanLink onClick={() => setIsUpdatingPaymentMethod(true)}>
                  Update Payment Method
                </SpanLink>
              </div>
            </p>
          </div>
          <Button
            disabled={subscriptionCreateForCustomerResults.loading}
            onClick={() => subscriptionCreateForCustomer()}
          >
            {subscribeCopy}
          </Button>
        </div>
      );
    default:
      return (
        <Alert variant="danger">
          Unable to load payment method. Please
          <SpanLink onClick={() => zendesk("webWidget", "open")}>
            {" "}
            contact us for help!
          </SpanLink>
        </Alert>
      );
  }
}
